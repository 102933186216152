@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Playball&family=Protest+Revolution&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.App {
  /* border: 1px solid red; */
  width: min(370px, 100%);
  /* height: 550px; */
  position: relative;
  /* overflow: hidden; */
}

/* navbar design */
.navBar-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: #f8f8f8;
}

.navBar-container .logo {
  display: flex;
  align-items: center;
}

.navBar-container .logo .logoImage {
  width: 15px;
  height: 15px;
}

.navBar-container .logo .logoLetter {
  font-weight: bolder;
  font-size: 14px;
}

.page-name {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
}

.bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  background-color: #f8f8f8;
}

.bottom-bar .bottom-icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
}

.bottom-icons .icon {
  font-size: 18px;
}

.bottom-drawer {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  z-index: 999;
  border-radius: 20px 20px 0 0;
  padding: 25px;
  transition: 0.5s ease;
  height: inherit;
}

.bottom-drawer.active {
  top: 100px;
  left: 0;
}

.sw-profiles .header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.sw-profiles .header .edge {
  width: 40px;
  border-radius: 99px;
  height: 2px;
  background-color: rgba(43, 43, 43, 0.37);
}
.sw-profiles .header .text {
  font-size: 15px;
  margin: 10px 0;
}

.user-profile {
  padding: 10px;
  display: flex;
  align-items: center;
  border: 2px solid #2daaa4;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  cursor: pointer;
}

.user-profile.add-new {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
}
.user-profile.add-new div {
  font-size: 12px;
  font-weight: 500;
}

.user-profile img {
  width: 50px;
  height: 50px;
}
.user-profile .useName_work .user-name {
  display: flex;
  font-weight: 500;
}
.user-profile .useName_work .user-work {
  font-size: 13px;
  color: #454545;
}

.user-profile .useName_work .profile-type {
  display: inline;
  font-size: 13px;
  margin-top: 5px;
  border: 1px solid #3b3b3b;
  font-weight: 400;
  border-radius: 99px;
  padding: 2px 4px;
}

.cancel-btn {
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
