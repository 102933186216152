
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --box-shadow: 0px 2px 5px #bebebe;
}

*{
  font-family: "Roboto", sans-serif !important
}

.profilesPage {
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 80px);
}

.accordion {
  width: calc(100%);
  border-radius: 10px;
  transition: 0.2s ease;
  padding: 17px 15px;
  box-shadow: var(--box-shadow);
  height: 110px;
  overflow: hidden;
  position: relative;
}

.accordion .arrow {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  cursor: pointer;
}

.accordion.active .arrow {
  transform: rotate(180deg);
  transform-origin: center;
}

.accordion.active {
  height: 275px;
}

.accordion .user-details {
  display: flex;
}
.accordion .user-details img {
  width: 50px;
  height: 50px;
}

.user-details .useName_work {
  display: flex;
  flex-direction: column;
  line-height: 15px;
}

.user-details .useName_work .user-name {
  font-weight: 500;
}

.user-details .useName_work .user-work {
  font-size: 13px;
  color: #454545;
}

.accordion .profile-type {
  display: inline;
  font-size: 13px;
  margin-top: 5px;
  border: 1px solid #3b3b3b;
  font-weight: 400;
  border-radius: 99px;
  padding: 2px 4px;
}

.hr-line {
  margin-top: 30px;
  border-top: 1.3px solid #474747;
}

.accordion-btns {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 9px;
  margin-top: 15px;
}

.accordion-btns .acc-btn {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #2c2b2bef;
  padding: 5px 10px;
  color: #f4f3f3;
  cursor: pointer;
  border-radius: 99px;
  transition: 0.3s ease;
}

.accordion-btns .acc-btn:hover {
  background-color: #1b1a1aef;
}

.accordion-btns .acc-btn .btn-icon {
  font-weight: bolder;
  margin-right: 6px;
}
.accordion-btns .acc-btn .acc-text {
  font-weight: 400;
}

/* second section */

.sec-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
div#root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sec-section .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 9px 8px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.sec-section .button .icon {
  font-size: 19px;
}

.sec-section .add-new-link {
  background-color: #3addd5;
  transition: 0.3s ease;
}

.sec-section .active-new {
  background-color: #2c2b2bef;
  flex-grow: 1;
  /* width: 100%; */
  transition: 0.3s ease;
  position: relative;
}

.input-in-popup {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px #777777;
  border-radius: 10px;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #2daaa4;
  z-index: 2;
}

.input-in-popup .icon {
  color: #3b3b3b;
  border-radius: 3px;
  padding: 5px;
  font-size: 23px;
  background-color: #f5f5f5;
  transition: 0.1s ease;
  cursor: pointer;
}
.input-in-popup .icon:hover {
  background-color: #dfdfdf;
}

.input-in-popup::after {
  content: "";
  position: absolute;
}
.input-in-popup input,
.input-in-popup-activate input {
  border: none;
  outline: none;
  color: #2daaa4;
}

.sec-section .add-new-link:hover {
  background-color: #2daaa4;
}

.sec-section .active-new:hover {
  background-color: #212121ef;
}

/* start third secton (links section) */

.third-section {
  padding: 15px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.third-section .header {
  display: flex;
  justify-content: space-between;
}

.third-section .header div:first-child {
  font-size: 14px;
}
.third-section .header div:last-child {
  font-size: 14px;
  color: #606060;
  font-size: 13px;
}

.links-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 99px;
}

.link .link-left {
  display: flex;
  align-items: center;
}
.link .link-left .link-icon {
  width: 25px;
  margin-right: 5px;
}
.link .link-left .link-text {
  font-size: 14px;
  font-weight: 500;
}

/* switch  design*/

.toggle-switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 27px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 27px;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  width: 27px;
  height: 27px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-input:checked + .toggle-label {
  background-color: #3addd5;
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(34px);
}

.pick-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.pick-link .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pick-link .content img {
  width: 25px;
  margin-right: 10px;
}
.pick-link .content h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #454545;
}

/* switch profiles content */
.dark-layout {
  position: absolute;
  top: -50px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0000003f;
  z-index: 1;
}

.profileImage .editProfileImage {
  position: absolute;
  z-index: 12323;
  bottom: 2px;
  right: 0;
  cursor: pointer;
}
.profileImage .editProfileImage img {
  width: 40px;
  height: 40px;
}
.profileImage > img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 50%;
}
.profileImage {
  width: 120px;
  height: 120px;
  position: relative;
  margin-top: 20px;
  border-radius: 50%;
}
.newProfileTage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 370px;
  flex-direction: column;
}

.newProfileMenu img {
  width: 200px;
  margin: auto;
  /* height: 40px; */
  /* border-radius: 50%; */
}
.additional .icon img {
  width: 20px;
}
.newProfileMenu .navTitle {
  margin: auto;
  font-size: 29px;
  color: #80808099;
  font-weight: 300;
}

.socialMedias img {
  width: 100px;
  cursor: pointer;
}

.socialMedias {
  max-width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  margin: 20px;
  grid-gap: 12px;
  gap: 32px;
  flex-wrap: wrap;
}

.bio {
  padding: 0 20px;
  /* text-align: center; */
  margin-right: auto;
}
.additional {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 10px;
}
.multiadditional {
  align-items: baseline;
}
.newProfileTage > h5 {
  color: rgba(0, 0, 0, 0.867);
  margin-top: 0;
}
.imageProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  align-items: center;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: white;
  height: 150px;
  border-radius: 30px;
  box-shadow: 1px 2px 24px -2px #80808057;
}
.df {
  margin-top: -100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.newProfileMenu {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  /* background: white; */
  height: 150px;
  /* border-radius: 30px; */
  /* box-shadow: 1px 2px 24px -2px #80808057; */
}

.columnDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 60px;
  margin-top: -50px;
  background: white;
  min-height: 150px;
  border-radius: 30px;
  box-shadow: 1px 2px 24px -2px #80808057;
}

.additionalInformations {
  width: 100%;
  padding: 20px;
}

.profilesBox .profileBox img {
  max-width: 100%;
}
.profileBox {
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  gap: 7px;
}

.profileBox img {
  width: 100%;
}

.profilesBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* gap: 32px;S */
}

h3 {
  margin: 0;
}

/* .profileImage {
  top: -120px;
  position: relative;
} */

.columnDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 60px;
  margin-top: -40px;
  background: white;
  min-height: 150px;
  border-radius: 30px;
  box-shadow: 1px 2px 24px -2px #80808057;
}

.additionalInformations {
  display: flex;
  background: white;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 1px 2px 24px -2px #80808057;
  flex-direction: column;
}

.additionalInformations .textContainer {
  display: flex;
  flex-direction: column;
}

.imageProfile img {
  width: 300px;
  position: relative;
  top: -20px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

.columnDiv p {
  color: darkgray;
}

button.btn_dark {
  margin: auto;
  padding: 10px 30px;
  border: none;
  background: black;
  transition: 0.5s ease-in-out;
  border: 3px solid black;
  color: white;
  border-radius: 30px;
  font-size: 700;
}

button.btn_dark:hover {
  background-color: white;
  color: black;
}


.multiadditional{
  flex-direction: column;
}


.icon {
  display: flex;
  align-items: center;
  gap: 7px;
  
}

.icon h3, .profileBox span, .profileBox a{
  font-size: 13px !important;
}